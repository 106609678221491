.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  position: relative;
}

.react-datepicker {
  font-family: unset;
  overflow: hidden;
}

.react-datepicker__input-container > input {
  width: 15.06625rem;
  font-family: unset;
  font-weight: 500;
  font-size: 0.875rem;
  height: 2.5rem;
  border: 1px solid #e3e3ec;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  color: #16014a;
}

.react-datepicker__input-container > input:hover {
  border: 1px solid #5e5eaa;
}

.react-datepicker__input-container > input:valid {
  background-color: #f8f8ff;
}

.react-datepicker__input-container > input:focus {
  background-color: #fff;
}

input.react-datepicker-ignore-onclickoutside {
  border: 0.0625rem solid #5e5eaa;
  border-bottom-width: 0;
  border-radius: 0.5rem 0.5rem 0 0;
  outline: none;
}

input.react-datepicker-ignore-onclickoutside:hover {
  border-bottom-width: 0;
}

.react-datepicker {
  font-weight: 500;
  border: 0.0625rem solid #5e5eaa;
  border-top-width: 1;
  border-top-left-radius: 0;
  color: #16014a;
}

.react-datepicker__header {
  font-family: unset;
  background-color: #ffffff;
  border-bottom: none;
}

.react-datepicker__time-container {
  border-left: 0.0625rem solid #e3e3ec;
}

.react-datepicker__header--time {
  padding-bottom: 0.75rem;
}

.react-datepicker__time-list {
  border-top: 1px solid #e3e3ec;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.react-datepicker__time-list::-webkit-scrollbar {
  display: none;
}

.react-datepicker-popper[data-placement^='bottom'] {
  margin-top: 0;
}

.react-datepicker-popper[data-placement^='top'] {
  margin-bottom: 0;
  height: 17.1875rem;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker {
  border-bottom-left-radius: 0;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day-names {
  margin-top: 0.75rem;
  padding-top: 0.25rem;
  border-top: 0.0625rem solid #e3e3ec;
}

.react-datepicker__month {
  margin: 0 0.4rem;
}

.react-datepicker__day {
  font-weight: 500;
  color: #16014a;
}

.react-datepicker__day--disabled {
  color: #9191ac;
}

.react-datepicker__day-name {
  color: #5e5eaa;
}

.react-datepicker-time__header,
.react-datepicker__current-month {
  font-family: unset;
  font-size: 0.875rem;
  font-weight: 600;
  color: #16014a;
}

.react-datepicker__navigation,
.react-datepicker__time-list-item,
.react-datepicker__day {
  outline: none;
}

.react-datepicker__day--selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #dbdbff;
  color: #16014a;
  font-weight: 500;
}

.react-datepicker__day--today.react-datepicker__day--keyboard-selected {
  background-color: #ffffff;
}

.react-datepicker__day--selected:hover,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover,
.react-datepicker__day--keyboard-selected:hover {
  background-color: #dbdbff;
}
